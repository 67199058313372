
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";

@Component
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.branchId,
    config: {},
    className: "CompanyBranch",
  });

  companyObjectDataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "company",
  });
  loaded: boolean = false;

  get caption(): any {
    return this.dataSource.model?.caption;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Компании",
        to: "/manage/compnaies/",
        exact: true,
      },
      {
        text: this.companyObjectDataSource.model?.caption,
        to: `/manage/companies/${+this.$route.params.id}`,
        exact: true,
      },
      {
        text: "Филиалы",
        to: `/manage/companies/${+this.$route.params.id}/branches`,
        exact: true,
      },
      {
        text: this.caption,
        disabled: true,
      },
    ];
  }

  async created() {
    await this.companyObjectDataSource.get();
    this.loaded = true;
  }
}
